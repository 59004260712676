import React, { useState, useContext, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useFetch from 'use-http'
import CssBaseline from '@mui/material/CssBaseline';
import { MainContext } from '../../Providers/MainContext';
import Box from '@mui/material/Box';
import { Elements, PaymentElement, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import { useNavigate } from 'react-router-dom'
import CheckoutForm from './CheckoutForm';
import { useTheme } from '@mui/material/styles';

export default function SubscriptionPage() {
  const navigate = useNavigate();
  const { jwt } = useContext(MainContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const theme = useTheme();

  const { get, post, response, error, data = {} } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  useEffect(() => { getUserInfo() }, [])
  useEffect(() => { getClientSecret() }, [])

  const getUserInfo = async () => {
    const resData = await get('/api/user');
    if (response.ok) {
      console.log(resData)
      setFirstName(resData.first_name);
      setLastName(resData.last_name);
      setEmail(resData.email);
    }
  }

  const getClientSecret = async () => {
    const resData = await get('/api/stripe');
    console.log(resData)
    if (response.ok) {
      setClientSecret(resData['client_secret']);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const updateUserInfo = async () => {
    await post('/api/user', { firstName, lastName });
    if (response.ok) {
      navigate('/')
    }
  }
  console.log(theme.palette.error)

  const appearance = {
    variables: {
      color: theme.palette.text.primary,
      colorBackground: theme.palette.background.default,
      colorText: theme.palette.text.primary,
      colorDanger: theme.palette.error.main,
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px',
    }
  };

  return (
    <Container >
      <CssBaseline />

      <Container disableGutters sx={{ pt: 8, pb: 6 }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

          <Typography component="h1" variant="h5">
            Subscribe now
          </Typography>
          <Box sx={{ mt: 3 }}>
            {clientSecret && stripePromise && <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
              <CheckoutForm />
            </Elements>}
          </Box>
        </Box>
      </Container>
    </Container>
  );
}