import React, { useContext } from 'react';
import { useFetch } from 'use-http';
import { MainContext } from '../../Providers/MainContext';

import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import QuizIcon from '@mui/icons-material/Quiz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Center from '../../Components/Center';
import Typography from '@mui/material/Typography';


export default function InqueryViewModal(props) {
  const { jwt } = useContext(MainContext);
  const [rejectionReason, setRejectionReason] = React.useState('');
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  const rejectInquery = async () => {
    await post(`/api/inquery/${props.inquery.id}/status`, { rejectionReason, status: 'REJECTED' })
    if (response.ok) {
      setRejectionReason('')
      props.onClose()
    }
  }

  if (!props.inquery) return null
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 2 }}>
              <QuizIcon />
            </Avatar>
          </Center>
          <Typography component="h1" variant="h5">
            Rejection reason for: {props.inquery.title}
          </Typography>
          <Box sx={{ mt: 2 }} component="form" noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="rejection"
              label="Rejection Reason"
              name="rejection"
              value={rejectionReason}
              onChange={e => setRejectionReason(e.target.value)}
            />
            <Center>
              <Button onClick={() => { rejectInquery() }}>
                Reject
              </Button>
            </Center>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}