import React, { useContext } from 'react';
import { useFetch } from 'use-http';
import { MainContext } from '../../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import QuizIcon from '@mui/icons-material/Quiz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Center from '../../Components/Center';
import Typography from '@mui/material/Typography';


export default function InqueryWithdrawModal(props) {
  const { jwt } = useContext(MainContext);
  const { delete: deleter, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  const withdrawInquery = async () => {
    await deleter(`/api/inquery/${props.inquery.id}`)
    if (response.ok) {
      props.onClose()
    }
  }

  if (!props.inquery) return null
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 2 }}>
              <QuizIcon />
            </Avatar>
          </Center>
          <Center>
            <Typography component="h1" variant="h5">
              Withdraw: {props.inquery.title}
            </Typography>
          </Center>
          <Typography>
            Once an inquery is withdrawn it can't be undone
          </Typography>
          <Box sx={{ mt: 2 }} component="form" noValidate>
            <Center>
              <Button onClick={() => { withdrawInquery() }}>
                Withdraw
              </Button>
            </Center>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}