import React, { useState, useContext } from 'react';
import useFetch from 'use-http'

import { MainContext } from '../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../Components/Center'
import ForgotPasswordModal from './ForgotPasswordModal';
import ForgotUsernameModal from './ForgotUsernameModal';

export default function LoginModal(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showForgotUsername, setShowForgotUsername] = useState(false)
  const { setJwt } = useContext(MainContext);
  const [error, setError] = useState('')
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, { cachePolicy: 'no-cache' })

  const login = async () => {
    setError('')
    let data = await post('/api/token', { username, password });
    if (response.ok) {
      setJwt(data.access, {
        days: 1,
        SameSite: 'Strict',
        Secure: true,
      })
      props.onClose()
    }
    if (response.status === 403 || response.status === 401) setError('Invalid Username or Password')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  }

  return (
    <>
      <ForgotUsernameModal open={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
      <ForgotPasswordModal open={showForgotUsername} onClose={() => setShowForgotUsername(false)} />
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
            <Center>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <br />
              <br />
              <Typography component="h1" variant="h5">
                Login
              </Typography>
            </Center>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                autoComplete="username"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onKeyDown={handleKeyDown}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <Grid container spacing={2}>
                <Grid item xs>
                  <Link sx={{ userSelect: 'none' }} onClick={() => setShowForgotPassword(true)}>
                    <Typography variant="body2" >
                      Forgot Password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link sx={{ float:'right', userSelect: 'none' }} onClick={() => setShowForgotUsername(true)}>
                    <Typography variant="body2" >
                      Forgot Username?
                    </Typography>
                  </Link>
                </Grid>
              </Grid>


            </Box>
            <br />
            {error && <Alert severity="error">{error}</Alert>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => login()}>
              Login
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}