
import React, { useState, useContext, useEffect } from 'react'
import { MainContext } from '../../Providers/MainContext';
import { useFetch } from 'use-http'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Center from '../../Components/Center';
import SearchIcon from '@mui/icons-material/Search';


import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';


export default function MessagePage() {
  const navigate = useNavigate();
  const { jwt, profile } = useContext(MainContext);
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(undefined);

  const { get, post, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: "no-cache" })
  useEffect(() => { getChat() }, [id])
  useEffect(() => { getChats() }, [])

  const setCurrentChat = (id) => {
    navigate(`/chat/${id}`)
  }

  async function sendMessage() {
    await post(`/api/message`, { message: message, chatId: id })
    if (response.ok) {
      setMessage("")
      getChat()
    }
  }

  async function getChats() {
    const _chats = await get('/api/chat')
    if (response.ok) {
      setChats(_chats)
    }
  }


  async function getChat() {
    if (!id) return;
    const _chat = await get('/api/chat/' + id)
    if (response.ok) {
      setSelectedChat(_chat)
    }
  }

  if (chats.length == 0) return (
    <Container maxWidth="xl">
      <CssBaseline />
      <Container disableGutters maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Center>
              <>
                <Typography variant="h4" sx={{ mt: 3 }} gutterBottom>
                You haven't started a conversation with anyone yet. 
                Check out the search page to find someone to talk with.
                </Typography>
                <Button sx={{ mt: 3 }} variant="contained" color="primary" onClick={() => navigate('/search')}> <SearchIcon /> Search Page</Button>
              </>
            </Center>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )

  return (
    <Container maxWidth="xl">
      {/* TODO delete modal */}

      <CssBaseline />
      <Container disableGutters maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
        <Grid container spacing={3}>
          <Grid item sx={{ overflow: 'scroll', height: '82vh' }} md={3}>
            {chats.map((chat) => (
              <div key={chat.id}>
                <Card >
                  <CardActionArea onClick={() => setCurrentChat(chat.id)}>
                    <CardHeader
                      title={chat.members.filter((member) => member.id != profile).map((member) => member.name).join(', ')}
                      avatar={
                        <Avatar>
                          {/* {chat.members.filter((member) => member.id != profile).map((member) => member.name).join(', ')[0]} */}
                          {chat.members.filter((member) => member.id != profile).map((member) => member.profile_pic ? <img width={50} height={50} src={process.env.REACT_APP_API_URL + member.profile_pic} /> : member.name[0])}
                        </Avatar>
                      }
                    />
                  </CardActionArea>
                </Card>
                <Divider />
              </div>
            ))}

          </Grid>
          <Grid item md={9}>
            {!id && <Paper sx={{ height: "82vh" }}>
              <Center>
                <Typography sx={{ mt: 5 }} variant="h4" component="h1" gutterBottom>
                  Select a chat
                </Typography>
              </Center>
            </Paper>}
            {selectedChat && <Paper sx={{ height: "82vh" }}>

              <Grid direction={"column"} >
                <Grid item xs={1}>
                  <Center>
                    <Typography sx={{ mt: 5 }} variant="h4" component="h1" gutterBottom>
                      {selectedChat.members.filter((member) => member.id != profile).map((member) => member.name).join(', ')}
                    </Typography>
                    <Divider></Divider>
                  </Center>
                </Grid>
                <Grid item xs={10} sx={{ overflow: 'scroll', height: '64vh', display: 'flex', flexDirection: 'column-reverse' }} >
                  {[...selectedChat.messages].reverse().map((_message) => (
                    <div key={_message.id}>
                      <Paper sx={{ ml: 3, mr: 3, width: "65%", pl: 3, pr: 3, pt: 1, pb: 1, float: _message.sender.id == profile ? 'right' : 'left' }}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <Avatar>{_message.sender.profile_pic ? <img width={50} height={50} src={_message.sender.profile_pic} /> : _message.sender.name[0]}</Avatar>
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            {/* TODO do something with newlines here */}
                            <Typography style={{ overflowWrap: 'break-word' }}>{_message.message}</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <br />
                      <br />
                      <br />
                    </div>
                  ))}
                </Grid>
                <Grid item xs={1}>
                  <Container>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="message"
                      id="message"
                      multiline
                      value={message}
                      onChange={e => setMessage(e.target.value)} />
                    <Button sx={{ float: 'right' }} onClick={() => { sendMessage(); }} disabled={!message.length}>
                      Send
                    </Button>
                  </Container>
                </Grid>

              </Grid>
            </Paper>
            }
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
