
import React, { useState, useContext, useEffect } from 'react';
import { useFetch } from 'use-http';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { MainContext } from '../../Providers/MainContext';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import InqueryViewModal from './InqueryViewModal';
import InqueryRejectionModal from './InqueryRejectionModal';
import InqueryWithdrawModal from './InqueryWithdrawModal';


export default function InqueriesPage() {
  const navigate = useNavigate();
  const { jwt, profile } = useContext(MainContext);
  const [data, setData] = useState([])
  const [currentProfile, setCurrentProfile] = useState(null)
  const { get, post, delete: deleter, response, loading, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  const [showInqueryModal, setShowInqueryModal] = useState(false)
  const [showInqueryWithdrawModal, setShowInqueryWithdrawModal] = useState(false)
  const [showInqueryRejectionModal, setShowInqueryRejectionModal] = useState(false)
  const [currentInquery, setCurrentInquery] = useState(null);

  useEffect(() => {
    getInqueries()
  }, [])

  useEffect(() => {
    getCurrentProfile()
  }, [])

  const getCurrentProfile = async () => {
    const _profile = await get(`/api/profile/${profile}`)
    if (response.ok) {
      setCurrentProfile(_profile)
    }
  }


  const getInqueries = async () => {
    const _data = await get('/api/inquery')
    if (response.ok) {
      setData(_data)
    }
  }

  const updateInqueryStatus = async (inquery, status) => {
    const _data = await post(`/api/inquery/${inquery.id}/status`, { status })
    if (response.ok) {
      getInqueries()
    }
  }

  const colorMap = {
    'PENDING': 'warning',
    'IN_REVIEW': 'primary',
    'REJECTED': 'error',
    'RESPONDED': 'success',
    'ACCEPTED': 'success'
  }

  const printMap = {
    'PENDING': 'Pending',
    'IN_REVIEW': 'In Review',
    'REJECTED': 'Rejected',
    'RESPONDED': 'Responded',
    'ACCEPTED': 'Accepted'
  }


  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <InqueryViewModal open={showInqueryModal} onClose={() => { setShowInqueryModal(false); }} inquery={currentInquery} />
      <InqueryRejectionModal open={showInqueryRejectionModal} onClose={() => { setShowInqueryRejectionModal(false); getInqueries() }} inquery={currentInquery} />
      <InqueryWithdrawModal open={showInqueryWithdrawModal} onClose={() => { setShowInqueryWithdrawModal(false); getInqueries() }} inquery={currentInquery} />
      <Container disableGutters maxWidth="md" sx={{ pt: 8, pb: 6 }}>
        <Typography component="h1">
          Inquiries
        </Typography>
        {error && 'Error!'}
        {/* {loading && 'Loading...'} */}
        {data && <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Genre</TableCell>
                  <TableCell>Referal</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((_inquery) => (
                  <TableRow key={_inquery}>
                    <TableCell>{_inquery.title}</TableCell>
                    <TableCell>{_inquery.genre}</TableCell>
                    <TableCell>{_inquery.referal}</TableCell>
                    {currentProfile && currentProfile.profile_type == 'AGENT' && <TableCell><Link onClick={() => navigate(`/profile/${_inquery.author.id}`)}>{_inquery.author.name}</Link></TableCell>}
                    {currentProfile && currentProfile.profile_type == 'AUTHOR' && <TableCell><Link onClick={() => navigate(`/profile/${_inquery.agent.id}`)}>{_inquery.agent.name}</Link></TableCell>}
                    <TableCell>  <Chip label={printMap[_inquery.status]} color={colorMap[_inquery.status]} /></TableCell>
                    <TableCell>
                      <Button onClick={() => {
                        setCurrentInquery(_inquery);

                        setShowInqueryModal(true);
                      }}>
                        View
                      </Button>
                      {currentProfile && currentProfile.profile_type == 'AGENT' && _inquery.status != 'REJECTED' && <Button onClick={() => {
                        setCurrentInquery(_inquery);
                        setShowInqueryRejectionModal(true)
                      }}>
                        Reject
                      </Button>}
                      {currentProfile && currentProfile.profile_type == 'AGENT' && _inquery.status != 'REJECTED' && <>
                          <Select
                            sx = {{ml: 2}}
                            labelId="change-status"
                            label="Change Status"
                            value={_inquery.status}
                            onChange={(event) => updateInqueryStatus(_inquery, event.target.value)}
                          >
                            <MenuItem value={'PENDING'}>Pending</MenuItem>
                            <MenuItem value={'IN_REVIEW'}>In Review</MenuItem>
                            <MenuItem value={'RESPONDED'}>Responded</MenuItem>
                            <MenuItem value={'ACCEPTED'}>Accepted</MenuItem>
                          </Select>
                        </>}
                      {currentProfile && currentProfile.profile_type == 'AUTHOR' && <Button onClick={() => {
                          setCurrentInquery(_inquery);
                          setShowInqueryWithdrawModal(true)
                        }} >
                          Withdraw
                        </Button>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        }
      </Container>
    </Container >
  );
}