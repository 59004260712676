import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import Markdown from './../Components/Markdown'
import post1 from '../Posts/example.js';
import post2 from '../Posts/post2.js';
import post3 from '../Posts/post3.js';


export default function ExpolorePage() {
  
  const posts = [post2, post3]
  console.log(post1)
  return(
    <>
      <Container maxWidth="xl">
        <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
         {posts.map((post) => (
          <>
            <Markdown className="markdown" key={post.substring(0, 40)}>
              {post}
            </Markdown>
            <br/>
            <Divider/>
            <br/>
          </>
        ))}
        </Container>
      </Container>
    </>
  );
}