import React, {useContext} from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import EarlyRegister from './Page/EarlyRegister'
import FaqPage from './Page/FaqPage';
import ExplorePage from './Page/ExplorePage';
import { MainContext } from './Providers/MainContext';
import ConstructionPage from './Page/Construction';
import ProfileCreatePage from './Page/Profile//ProfileCreatePage';
import ProfileEditPage from './Page/Profile//ProfileEditPage';
import SettingsPage from './Page/SettingsPage';
import ConnectionPage from './Page/Connection/ConnectionPage';
import MessagePage from './Page/Message/MessagePage';
import LoggedInAppBar from "./Components/LoggedInAppBar";
import LoggedOutAppBar from "./Components/LoggedOutAppBar";
import ProfilePage from './Page/Profile/ProfilePage';
import SubscriptionPage from './Page/Subscription/SubscribePage';
import SearchPage from './Page/SearchPage';
import InqueryPage from './Page/Inquery/InqueryPage';
import InqueryCreatePage from './Page/Inquery/InqueryCreatePage';
import ResetPasswordPage from './Page/ResetPasswordPage';


export default function Router() {
  const { jwt } = useContext(MainContext);
  const { theme } = useContext(MainContext);
  const _theme = createTheme({
    palette: {
      mode: theme,
    },
  });

  return (
    <ThemeProvider theme={_theme}>
      {/* Fancy magic shit here to force re-renders on page load*/}
      <BrowserRouter key={window.location.pathname}> 
        {!jwt && <>
          <LoggedOutAppBar/>
          <Routes>
            <Route path="/" element={<EarlyRegister />} />
            <Route path="/home" element={<EarlyRegister />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile/:id" element={<ProfilePage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </> }
        {jwt && <>
          <LoggedInAppBar/>
          <Routes>
            <Route path="/" element={<ProfilePage />} />
            <Route path="/home" element={<ProfilePage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile/:id" element={<ProfilePage />} />
            <Route path="/chat" element={<MessagePage />} />
            <Route path="/chat/:id" element={<MessagePage />} />
            <Route path="/create-profile" element={<ProfileCreatePage />} />
            <Route path="/edit-profile" element={<ProfileEditPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/inquery" element={<InqueryPage />} />
            <Route path="/create-inquery/:id" element={<InqueryCreatePage />} />
            <Route path="/connection" element={<ConnectionPage />} />
            <Route path="/construction" element={<ConstructionPage />} />
            <Route path="/settings" element={<SettingsPage />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>}
      </BrowserRouter>
    </ThemeProvider>
  );
}