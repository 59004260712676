import React, { useState, useContext, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import useFetch from 'use-http'

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import { MainContext } from '../../Providers/MainContext';
import Center from '../../Components/Center';
import FormControlLabel from '@mui/material/FormControlLabel';

import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useNavigate } from 'react-router-dom'

export default function ProfileEditPage() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [twitter, setTwitter] = useState('');
  const [acceptingQueries, setAcceptingQueries] = useState(true);
  const [profileType, setProfileType] = useState('AUTHOR');
  const [profilePic, setProfilePic] = useState(null);
  const [imgData, setImgData] = useState(null);
  const { jwt, profile } = useContext(MainContext);
  const { get, patch, response, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  useEffect(() => {
    getProfile();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const getProfile = async () => {
    const data = await get(`/api/profile/${profile}`);
    if (response.ok) {
      setName(data.name)
      setBio(data.bio)
      setWebsite(data.website)
      setProfileType(data.profile_type)
      setImgData(data.profile_pic)
      setEmail(data.email)
      setTwitter(data.twitter)
      setPhone(data.phone)
      setAcceptingQueries(data.accepting_queries)
    }
  }


  const updateProfile = async () => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('bio', bio);
    formData.append('website', website);
    formData.append('profile_type', profileType);
    formData.append('email', email);
    formData.append('twitter', twitter);
    formData.append('phone', phone);
    formData.append('accepting_queries', acceptingQueries);

    if (profilePic !== null) formData.append('profile_pic', profilePic);
    await patch(`/api/profile/${profile}`, formData);
    if (response.ok) {
      navigate('/')
    }
  }

  const onChangeProfilePic = (e) => {
    if (e.target.files[0] === undefined) return;
    setProfilePic(e.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  console.log(profilePic)


  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AccountCircle />
          </Avatar>
          <Typography component="h1" variant="h5">
            Edit your profile
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              onChange={onChangeProfilePic}
              type="file"
            />
            <Center>
              <label htmlFor="raised-button-file">
                {!imgData && <Avatar sx={{ height: 200, width: 200 }} variant="raised" component="span" >
                  <PersonAddAlt1Icon />
                </Avatar>}
                {imgData && <Avatar sx={{ height: 200, width: 200 }} variant="raised" component="span" src={imgData} />}
              </label>
            </Center>

            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="name"
            />
            <TextField
              margin="normal"
              fullWidth
              name="website"
              label="Optional Website"
              type="website"
              id="website"
              value={website}
              onChange={e => setWebsite(e.target.value)}
              autoComplete="website"
            />
            <TextField
              margin="normal"
              fullWidth
              name="twitter"
              label="Optional Twitter"
              type="website"
              id="twitter"
              value={twitter}
              onChange={e => setTwitter(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="email"
              label="Optional Email"
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
            />
            <TextField
              margin="normal"
              fullWidth
              name="phone"
              label="Optional Phone"
              type="phone"
              id="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              autoComplete="phone"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              multiline={true}
              minRows={4}
              maxRows={4}
              name="bio"
              label="Bio"
              id="bio"
              value={bio}
              onChange={e => setBio(e.target.value)}
            />
            <FormControlLabel sx={{ mb: 2 }} control={<Checkbox onChange={(event) => setAcceptingQueries(event.target.checked)} checked={acceptingQueries} />} label="Accepting Inqueries" />

            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profileType}
              label="Account Type"
              onChange={e => setProfileType(e.target.value)}
            >
              <MenuItem value={'AUTHOR'}>Author</MenuItem>
              <MenuItem value={'AGENT'}>Agent</MenuItem>
              <MenuItem value={'EDITOR'}>Editor</MenuItem>
              <MenuItem value={'GHOST_WRITER'}>Ghost Writer</MenuItem>
              <MenuItem value={'ADVISOR'}>Advisor</MenuItem>
              <MenuItem value={'ILLUSTRATOR'}>Illustrator</MenuItem>
              <MenuItem value={'PUBLISHER'}>Publisher</MenuItem>
            </Select>
            <br />

            <Alert style={{ visibility: error ? 'visible' : 'hidden' }} severity="error">
              Error Updating Profile
            </Alert>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => updateProfile()}>
              Save
            </Button>

          </Box>
        </Box>
      </Container>
    </Container>
  );
}