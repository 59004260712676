
import React, { useState, useContext, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useParams } from "react-router-dom";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { MainContext } from '../../Providers/MainContext';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Center from '../../Components/Center';
import { genreOptions } from '../../Util/constants';

import { useNavigate } from 'react-router-dom';



export default function InqueriesPage() {
  const { id } = useParams();
  const { jwt } = useContext(MainContext);
  const navigate = useNavigate();

  const [agent, setAgent] = useState([])
  const [title, setTitle] = useState('')
  const [genre, setGenre] = useState('OTHER')
  const [hasBeenRepresented, setHasBeenRepresented] = useState(false)
  const [hasBeenPublished, setHasBeenPublished] = useState(false)
  const [hasBeenSelfPublished, setHasBeenSelfPublished] = useState(false)
  const [synopsis, setSynopsis] = useState('')
  const [targetAudience, setTargetAudience] = useState('')
  const [referal, setReferal] = useState('')
  const [email, setEmail] = useState('')
  const [errorText, setErrorText] = useState('')

  const { get, post, response, loading, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  useEffect(() => {
    getAgent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAgent = async () => {
    const _agent = await get(`/api/profile/${id}`)
    if (response.ok) {
      setAgent(_agent)
    } else {
      navigate('/')
    }
  }

  const createInquery = async () => {
    const args = {
      title,
      genre,
      email,
      hasBeenRepresented,
      hasBeenPublished,
      hasBeenSelfPublished,
      synopsis,
      targetAudience,
      referal,
      agent: agent.id,
    }
    await post('/api/inquery', args);
    if (response.ok) {
      navigate('/inquery', { replace: true })
    } else {
      const json_res = await response.json()
      setErrorText(json_res['error'])
    }
  }


  return (
    <Container maxWidth="xl">
      <CssBaseline />
      <Container disableGutters maxWidth="md" sx={{ pt: 8, pb: 6 }}>
        <Typography component="h1">
          Create An Inquery
        </Typography>
        <Typography>
          Please ensure you have read this agents submission guidelines before submitting.

        </Typography>
        {/* {error && 'Error!'} */}
        {loading && 'Loading...'}
        {agent && <>
          <br />
          {error && <Alert severity="error">{errorText}</Alert>}
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Book Title"
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              value={genre}
              labelId="genre-helper-label"
              id="genre-select-helper"
              label="Genre"
              select
              onChange={e => setGenre(e.target.value)}>
              {genreOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              id="targetAudience"
              label="Target Audience"
              name="targetAudience"
              value={targetAudience}
              onChange={e => setTargetAudience(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              multiline
              required
              rows={4}
              id="synopsis"
              label="Synopsis"
              name="synopsis"
              value={synopsis}
              onChange={e => setSynopsis(e.target.value)}
            />

            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox onChange={(event) => setHasBeenPublished(event.target.checked)} checked={hasBeenPublished} />} label="Previously Published" />
            <br />
            <FormControlLabel sx={{ mb: 1 }} control={<Checkbox onChange={(event) => setHasBeenRepresented(event.target.checked)} checked={hasBeenRepresented} />} label="Previously Represented by an Agent" />
            <br />
            <FormControlLabel sx={{ mb: 1 }} control={<Checkbox onChange={(event) => setHasBeenSelfPublished(event.target.checked)} checked={hasBeenSelfPublished} />} label="Previously Self Published" />
            <br />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Contact Email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="referal"
              label="Refered By"
              name="referal"
              value={referal}
              onChange={e => setReferal(e.target.value)}
            />
            <br />
            To protect your intellectual property we disallow direct book uploads.
            <br />
            Please only send copies of your book to emails of agents/editors/publishers you have verified.
          </Box>

          <Center>
            <Stack direction="row" spacing={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => createInquery()}>
                Submit
              </Button>
            </Stack>
          </Center>
        </>
        }
      </Container>
    </Container>
  );
}