import React, { useState, useContext } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import useFetch from 'use-http'

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import { MainContext } from '../../Providers/MainContext';

import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { useNavigate } from 'react-router-dom'

export default function ProfileCreatePage() {
  const navigate = useNavigate();
  const [name, setName] = useState('');

  const [bio, setBio] = useState('');
  const [website, setWebsite] = useState('');
  const [profileType, setProfileType] = useState('AUTHOR');
  const { jwt, setProfile } = useContext(MainContext);
  const [errorText, setErrorText] = useState(null);
  const { post, response, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const create_profile = async () => {
    let res = await post('/api/profile', { name, bio, website, profile_type: profileType });
    if (response.ok) {
      await setProfile(res.id)
      await post('/api/profile/current', { id: res.id });
      navigate('/')
      navigate(0) // This forces a relod of the page idfk why a normal nav doesn't work but it needs this shit
    } else {
      const json_res = await response.json()
      setErrorText(json_res['error'])
    }
  }

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AccountCircle />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create your profile
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="name"
            />
            <TextField
              margin="normal"
              fullWidth
              name="website"
              label="Website"
              type="website"
              id="website"
              value={website}
              onChange={e => setWebsite(e.target.value)}
              autoComplete="website"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              multiline={true}
              minRows={4}
              maxRows={4}
              name="bio"
              label="Bio"
              id="bio"
              value={bio}
              onChange={e => setBio(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profileType}
              label="Account Type"
              onChange={e => setProfileType(e.target.value)}
            >
              <MenuItem value={'AUTHOR'}>Author</MenuItem>
              <MenuItem value={'AGENT'}>Agent</MenuItem>
              <MenuItem value={'EDITOR'}>Editor</MenuItem>
              <MenuItem value={'GHOST_WRITER'}>Ghost Writer</MenuItem>
              <MenuItem value={'ADVISOR'}>Advisor</MenuItem>
              <MenuItem value={'ILLUSTRATOR'}>Illustrator</MenuItem>
              <MenuItem value={'PUBLISHER'}>Publisher</MenuItem>
            </Select>
            <br />

            {error && <Alert sx={{ mt: 2 }} severity="error">{errorText}</Alert>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => create_profile()}>
              Create Profile
            </Button>

          </Box>
        </Box>
      </Container>
    </Container>
  );
}