import React, { useState, useContext, useEffect } from 'react';
import { useFetch } from 'use-http';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { MainContext } from '../Providers/MainContext';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

import Center from '../Components/Center';


export default function SearchPage() {
  const { jwt, profile } = useContext(MainContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const { get, response, error } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' });

  const search = async () => {
    const _data = await get(`/api/profile/search?q=${searchTerm}`);
    if (response.ok) {
      setHasSearched(true);
      setSearchResults(_data);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  }

  const _renderProfileSearchResults = (result) => {
    return( 
      <Paper onClick={() => navigate(`/profile/${result.id}`)} sx={{m:5, p:3}}>
        <Grid container spacing={3}>
          <Grid item lg={2}>
            <Avatar sx={{height: 135, width: 135}} alt={result.name} src={result.profile_pic} />
          </Grid>
          <Grid item lg={10}>
            <Typography component="h1">
              {result.name}
            </Typography>
            <Typography>
              {result.bio}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
        <Typography component="h1">
          Search
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              name="Search"
              label="Search for profiles"
              id="search"
              value={searchTerm}
              onKeyDown={handleKeyDown}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Button sx={{ mt: 1 }} size="large" onClick={() => search()}><SearchIcon /></Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            {searchResults.map((result) => _renderProfileSearchResults(result))}
            {searchResults.length === 0 && hasSearched && <Center><Typography component="h3" sx={{mt:3}}>No results found</Typography></Center>}
          </Grid>
        </Grid>


      </Container>
    </Container>
  );
}