import React, { useState, useContext, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useFetch from 'use-http'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import CssBaseline from '@mui/material/CssBaseline';
import { MainContext } from '../Providers/MainContext';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom'

export default function SettingsPage() {
  const navigate = useNavigate();
  const { jwt } = useContext(MainContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [messageNotificaiton, setMessageNotification] = useState(true);
  const [connectionNotification, setConnectionNotification] = useState(true);
  const [requestNotification, setRequestNotification] = useState(true);
  const [queryNotification, setQueryNotification] = useState(true);
  const { get, post, patch, response, error, data = {} } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  useEffect(() => { getUserInfo() }, [])

  const getUserInfo = async () => {
    const resData = await get('/api/user/settings');
    if (response.ok) {
      console.log(resData);
      setFirstName(resData.first_name);
      setLastName(resData.last_name);
      setMessageNotification(resData.message_notification);
      setConnectionNotification(resData.connection_notification);
      setRequestNotification(resData.request_notification);
      setQueryNotification(resData.query_notification);
    }
  }
  console.log(firstName)
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const updateUserInfo = async () => {
    await patch('/api/user/settings', { first_name: firstName, last_name: lastName, message_notification: messageNotificaiton, connection_notification: connectionNotification, request_notification: requestNotification, query_notification: queryNotification });
    if (response.ok) {
      navigate('/')
    }
  }

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

          <Typography component="h1" variant="h5">
            Update Your User Info
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              autoComplete="firstName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              autoComplete="lastName"
            />

            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox onChange={(event) => setMessageNotification(event.target.checked)} checked={messageNotificaiton} />} label="Message Notifications" />
            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox onChange={(event) => setConnectionNotification(event.target.checked)} checked={connectionNotification} />} label="Connection Notifications" />
            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox onChange={(event) => setQueryNotification(event.target.checked)} checked={queryNotification} />} label="Query Notifications" />
            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox onChange={(event) => setRequestNotification(event.target.checked)} checked={requestNotification} />} label="Request Notifications" />

            <Alert style={{ visibility: error ? 'visible' : 'hidden' }} severity="error">
              {data.message}
            </Alert>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => updateUserInfo()}>
              Update User Info
            </Button>

          </Box>
        </Box>
      </Container>
    </Container>
  );
}