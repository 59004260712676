import React from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import QuizIcon from '@mui/icons-material/Quiz';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Center from '../../Components/Center';

import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

const colorMap = {
  'PENDING': 'warning',
  'IN_REVIEW': 'primary',
  'REJECTED': 'error',
  'RESPONDED': 'success',
  'ACCEPTED': 'success'
}

const printMap = {
  'PENDING': 'Pending',
  'IN_REVIEW': 'In Review',
  'REJECTED': 'Rejected',
  'RESPONDED': 'Responded',
  'ACCEPTED': 'Accepted'
}

export default function InqueryViewModal(props) {
  const navigate = useNavigate();
  if (!props.inquery) return null
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 2 }}>
              <QuizIcon />
            </Avatar>
          </Center>
          <Center>
            <Chip label={printMap[props.inquery.status]} color={colorMap[props.inquery.status]} />
          </Center>
          <Box component="form" noValidate>
            <Center>
              <Typography sx={{ mt: 2 }} component="h1" variant="h3">
                {props.inquery.title}
              </Typography>
            </Center>
            <Typography sx={{ mt: 1 }} component="h1" variant="h6">
              Email: {props.inquery.email || "N/A"}
            </Typography>
            <Typography sx={{ mt: 1 }} component="h1" variant="h6">
              Author Profile: <Link onClick={() => navigate(`/profile/${props.inquery.author.id}`)}>{props.inquery.author.name}</Link>
            </Typography>
            <FormControlLabel sx={{ mt: 1, mb: 1 }} control={<Checkbox disabled checked={props.inquery.hasBeenPublished} />} label="Previously Published" />
            <br />
            <FormControlLabel sx={{ mb: 1 }} control={<Checkbox disabled checked={props.inquery.hasBeenRepresented} />} label="Previously Represented by an Agent" />
            <br />
            <FormControlLabel sx={{ mb: 1 }} control={<Checkbox disabled checked={props.inquery.hasBeenSelfPublished} />} label="Previously Self Published" />
            <br />
            <Typography sx={{ mt: 1 }} component="h1" variant="h6">
              Refered By: {props.inquery.referal || "N/A"}
            </Typography>
            <Typography sx={{ mt: 1 }} component="h1" variant="h6">
              Genre: {props.inquery.genre}
            </Typography>
            <Typography sx={{ mt: 1 }} component="h1" variant="h6">
              Synopsis:
            </Typography>
            <Typography component="h1" variant="h6">
              {props.inquery.synopsis}
            </Typography>
            {
              props.inquery.rejectionReason &&
              <Typography component="h1" variant="h6">
                Rejection Reason: {props.inquery.rejectionReason}
              </Typography>
            }
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}