import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useFetch from 'use-http'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { CssBaseline } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Center from '../../Components/Center';
import MessageModal from './MessageModal';

import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import QuizIcon from '@mui/icons-material/Quiz';
import MessageIcon from '@mui/icons-material/Message';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LanguageIcon from '@mui/icons-material/Language'; // WebsiteIcon
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';

import { MainContext } from '../../Providers/MainContext';

import ConnectionModal from './ConnectionModal';
import BookCreateModal from './BookCreateModal';
import BookViewModal from './BookViewModal';
import BookeEditModal from './BookEditModal';

import { checkJwt } from '../../Util/JwtChecker';


export default function ProfilePage() {
  const { id } = useParams();
  const { profile, jwt } = useContext(MainContext);
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showBookCreateModal, setShowBookCreateModal] = useState(false);
  const [showBookViewModal, setShowBookViewModal] = useState(false);
  const [showBookEditModal, setShowBookEditModal] = useState(false);
  const [currentBook, setCurrentBook] = useState({});

  const [profileData, setProfileData] = useState({});
  const [connectionsData, setConnectionsData] = useState(null);
  const [booksData, setBooksData] = useState([]);

  // use the profile from the context if no id is provided
  // making /profile/ be your profile
  let nonce = profile
  let url = null
  if (profile) { url = '/api/profile/' + profile }
  if (id) { url = '/api/profile/' + id; nonce = id }
  if (!checkJwt(jwt) && id) { url = '/api/public/profile/' + id; nonce = id }
  const { get, response } = useFetch(process.env.REACT_APP_API_URL, { headers: checkJwt(jwt) && { "Authorization": "Bearer " + jwt }, cachePolicy: "no-cache" })

  // const { loading: profileLoading, error: profileError, data: profileData = {} } = useFetch(process.env.REACT_APP_API_URL + url, { headers: { "Authorization": "Bearer " + jwt } }, [nonce])

  useEffect(() => {
    getProfile()
  }, [nonce])
  useEffect(() => {
    getBooks()
  }, [nonce])
  useEffect(() => {
    getConnection()
  }, [nonce])

  const getProfile = async () => {
    if (!url) return
    const _data = await get(url)
    if (response.ok) {
      setProfileData(_data)
      setError('')
      getBooks()
    }
  }

  const getConnection = async () => {
    if (!checkJwt(jwt) || !id) {
      return;
    }
    const _data = await get(`/api/connection?profile=${id}`)
    if (response.ok) {
      if (_data.length == 0) {
        return
      }
      setConnectionsData(_data[0])
      setError('')
    }
  }

  const getBooks = async () => {
    if (!checkJwt(jwt)) {
      return;
    }
    const profileId = id || profile
    if (!profileId) return
    const _data = await get(`/api/book?profile=${profileId}`)
    if (response.ok) {
      setBooksData(_data.reverse())
      setError('')
    }
  }

  const _renderBook = (book) => {
    if (!checkJwt(jwt)) {

    }
    return (
      <Card>
        <CardMedia
          sx={{ height: 280 }}
          image={book.cover_pic}
          title="green iguana"
        />
        <CardContent sx={{ height: 140, overflow: 'hidden' }}>
          <Typography component="h3">
            {book.title}
          </Typography>
          <Typography>
            {book.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => { setCurrentBook(book); setShowBookViewModal(true); }}>More Details</Button>
          {profileData.id == profile && <Button size="small" onClick={() => { setCurrentBook(book); setShowBookEditModal(true); }}>Edit</Button>}
        </CardActions>
      </Card>
    )
  }

  const _renderLockSection = () => {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            To view these books you must register.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Container maxWidth="xl">
      <MessageModal open={showMessageModal} onClose={() => setShowMessageModal(false)} profile={profileData} />
      <ConnectionModal open={showConnectionModal} onClose={() => setShowConnectionModal(false)} profile={profileData} />
      <BookCreateModal open={showBookCreateModal} onClose={() => { setShowBookCreateModal(false); getBooks(); }} profile={profileData} />
      <BookViewModal open={showBookViewModal} onClose={() => { setShowBookViewModal(false); }} profile={profileData} book={currentBook} />
      <BookeEditModal open={showBookEditModal} onClose={() => { setShowBookEditModal(false); getBooks(); }} profile={profileData} book={currentBook} />
      <CssBaseline />
      <Container disableGutters maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
        <>
          {profileData &&
            <>
              <Center>
                <>
                  {(!profileData || !profileData.profile_pic) && <Avatar sx={{ height: 200, width: 200 }}>
                    <AccountCircle />
                  </Avatar>}
                  {profileData && profileData.profile_pic && <Avatar sx={{ height: 200, width: 200 }} src={profileData.profile_pic} alt="profile picture" />}
                  <Typography component="h1">
                    {profileData.name}
                  </Typography>
                  <Typography component="h4" sx={{ mt: -3, mb: 3 }}>
                    {profileData.profile_type}
                  </Typography>
                  {profileData.id != profile && checkJwt(jwt) &&
                    <Stack direction="row" spacing={3} sx={{ mb: 6 }}>
                      {!connectionsData && <Button variant="contained" onClick={() => setShowConnectionModal(true)}>
                        <PersonAddIcon />
                        &nbsp;Connect
                      </Button>}
                      {connectionsData && <Button variant="outlined" disabled onClick={() => navigate('/connection')}>
                        <PersonAddIcon />
                        {connectionsData.accepted ? <>&nbsp;Connected</> : <>&nbsp;Pending</>}
                      </Button>}
                      <Button variant="contained" onClick={() => setShowMessageModal(true)}>
                        <MessageIcon />
                        &nbsp;Message
                      </Button>
                      {profileData.accepting_queries && profileData.profile_type == 'AGENT' && id && <Button variant="contained" onClick={() => navigate(`/create-inquery/${id}`)}>
                        <QuizIcon />
                        &nbsp;Inquery
                      </Button>}
                    </Stack>
                  }
                  {profileData.id == profile && <Stack direction="row" spacing={3} sx={{ mb: 6 }}>
                    <Button variant="contained" onClick={() => navigate('/edit-profile')}>
                      <EditIcon />
                      &nbsp;Edit Profile
                    </Button>
                  </Stack>
                  }
                </>
              </Center>
              <Paper elevation={6}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography component="h3">
                        About
                      </Typography>
                      <Typography >
                        {profileData.bio}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="h3">
                        Contact
                      </Typography>
                      {profileData.email && <Typography >
                        {profileData.email}
                      </Typography>}
                      {profileData.phone && <Typography >
                        {profileData.phone}
                      </Typography>}
                      {profileData.website &&
                        <Typography >
                          {profileData.website}
                        </Typography>}
                      {profileData.twitter &&
                        <Typography >
                          {profileData.twitter}
                        </Typography>}
                    </Grid>
                  </Grid>
                </Container>
                <br />
              </Paper>
              <br />
              <Typography component="h1">
                Books Authored {profileData.id == profile && <AddBoxIcon onClick={() => setShowBookCreateModal(true)} />}
              </Typography>
              <Paper elevation={6}>
                <Grid container spacing={2} sx={{ pl: 3, pr: 3 }}>
                  {!checkJwt(jwt) && <>
                    <Grid item sm={4} />
                    <Grid item sm={4}>
                      {_renderLockSection()}
                    </Grid>
                  </>
                  }

                  {booksData.map((_book) => {
                    return (
                      <Grid key={_book.id} item sm={3}>
                        {_renderBook(_book)}
                      </Grid>
                    )
                  })}
                </Grid>
                <br />
              </Paper>
            </>
          }
        </>
      </Container>
    </Container>
  );
}