import React, { useContext, useEffect } from 'react';
import useFetch from 'use-http';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { MainContext } from '../Providers/MainContext';
import PeopleIcon from '@mui/icons-material/People';
import CircleIcon from '@mui/icons-material/Circle';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import SearchIcon from '@mui/icons-material/Search';
import QuizIcon from '@mui/icons-material/Quiz';
//const pages = ['Products', 'Pricing', 'Blog'];
const pages = [];

const LoggedInAppBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profiles, setProfiles] = React.useState([]);
  const { jwt, setJwt, setProfile, theme, setTheme, profile } = useContext(MainContext);
  const { get, post, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })

  useEffect(async () => {
    let profiles = await get('/api/profile')
    if (response.status === 404 && location.pathname !== '/create-profile') {
      navigate('/create-profile')
    } else if (response.ok) {
      if (profiles.length > 0) {
        setProfiles(profiles)
        setProfile(profiles[0].id)
      }
    }
  }, [profile])

  const handleSwitchProfile = async (profileId) => {
    await post('/api/profile/current', { id: profileId });
    if (response.ok) {
      await setProfile(profileId);
      navigate('/');
    }
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    setJwt('');
    setProfile(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h4"
            noWrap
            component="div"
            to="/"
            sx={{ mr: 3, display: { xs: 'none', md: 'flex' }, fontFamily: 'Dancing Script', fontWeight: 900 }}>
            Literary Co
          </Typography>
          {/* Drop down menu bar for small setups */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, fontFamily: 'Dancing Script', fontWeight: 900 }}>
            Literary Co
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                component={RouterLink}
                to={`/${page}`}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', textAlign: 'center' }}>
                {page}
              </Button>
            ))}
          </Box>
          {profiles.length > 0 && <>
            <Box sx={{ flexGrow: 0, mr: 3 }}>
              <Tooltip title="Home">
                <IconButton onClick={() => navigate('/')} sx={{ p: 0 }}>
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0, mr: 3 }}>
              <Tooltip title="Search">
                <IconButton onClick={() => navigate('/search')} sx={{ p: 0 }}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0, mr: 3 }}>
              <Tooltip title="Messages">
                <IconButton onClick={() => navigate('/chat')} sx={{ p: 0 }}>
                  <MessageIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0, mr: 3 }}>
              <Tooltip title="Inqueries">
                <IconButton onClick={() => navigate('/inquery')} sx={{ p: 0 }}>
                  <QuizIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0, mr: 3 }}>
              <Tooltip title="Connections">
                <IconButton onClick={() => navigate('/connection')} sx={{ p: 0 }}>
                  <PeopleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {profiles.length > 0 && <> <MenuItem onClick={() => { handleCloseUserMenu(); navigate('/settings'); }}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography textAlign="center">Settings</Typography>
                </ListItemText>
              </MenuItem>
                <Divider />
              </>
              }
              <MenuItem onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} >
                <ListItemIcon>
                  {theme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
                </ListItemIcon>
                <ListItemText>
                  <Typography textAlign="center">Toggle theme</Typography>
                </ListItemText>
              </MenuItem>

              <Divider />
              {profiles.length > 0 && profiles.map((_profile) => (
                <MenuItem key={_profile.id} onClick={() => { handleCloseUserMenu(); handleSwitchProfile(_profile.id) }}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography textAlign="center">{_profile.name}</Typography>

                  </ListItemText>
                  {_profile.id == profile && <CircleIcon sx={{ ml: 1, color: 'primary.main' }} />}
                </MenuItem>
              ))}
              {profiles.length > 0 && <Divider />}
              <MenuItem onClick={() => { handleCloseUserMenu(); logout(); }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography textAlign="center">Logout</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default LoggedInAppBar;