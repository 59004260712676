import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';

export default function FaqPage() {

  return(
    <>
      <Container maxWidth="xl">
        <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            What Is Literary Co ?
          </Typography>
          <Typography variant="h5" align="left" color="text.secondary" component="p">
            Founded in 2021 Literary Co is a Social Networking Platform similar to LinkedIn designed to simplifiy the pulbishing process.
            If you've have trouble finding a literary agent or issues with too many representation requests,
            we are here to help. 
            <br/><br/>
            Our mission is to streamline the day to day process for Authors, Agents, and Editors.  
          </Typography>
        </Container>
        <Container disableGutters maxWidth="sm" sx={{pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            When Can I Use The App ?
          </Typography>
          <Typography variant="h5" align="Left" color="text.secondary" component="p">
            Our beta begins in August. The sooner you register the sooner you will get access.
          </Typography>
        </Container>
        <Container disableGutters maxWidth="sm" sx={{pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Can I Represent My Publishing Company Here ?
          </Typography>
          <Typography variant="h5" align="left" color="text.secondary" component="p">
            Currently no, we will be adding support for that in the near future. 
          </Typography>
        </Container>
        <Container disableGutters maxWidth="sm" sx={{pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            How Much Does It Cost ?
          </Typography>
          <Typography variant="h5" align="left" color="text.secondary" component="p">
            Free... Yes Free. We make money with advertisements and premium analytics subscriptions for authors. 
          </Typography>
        </Container>
      </Container>
    </>
  );
}