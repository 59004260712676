import React, { useState, useContext } from 'react';
import useFetch from 'use-http'

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Copyright from '../Components/Copyright'

import {MainContext} from '../Providers/MainContext'

export default function RegPage() {
  const {setJwt} = useContext(MainContext)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  // const [type, setType] = useState('AUTHOR');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [randomScreen] = useState((Math.floor(Math.random() * 4) + 1));
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { post, response, error } = useFetch(process.env.REACT_APP_API_URL, {cachePolicy: 'no-cache'})

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const clearPage = () => {
    setUsername('')
    setPassword('')
    setEmail('')
    setFirstName('')
    setLastName('')
    setPassword2('')
    setShowSuccessModal(false)
  }

  const register_user = async () => {
    await post('/api/register', { email, username, password, password2, first_name: firstName, last_name: lastName });
    if (response.ok){
      setShowSuccessModal(true)
      let res = await post('/api/token', { username, password});
      if(res){
        setJwt(res.access)
      }
    }
  }

  const SuccessModal = () => { 
    return(
      <Dialog open={showSuccessModal} onClose={() => clearPage()}>
      <DialogTitle><center>Account Created Successfully</center></DialogTitle>
      <center>
        <CheckCircleIcon sx={{ fontSize: 80, color: 'green' }}/>
      </center>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for signing up {firstName}  
          </DialogContentText>
        </DialogContent>
    </Dialog>
    )
  }

  return (
    <>
      <SuccessModal/>
      <Container maxWidth="xl">
        <br/><br/>

        <Grid container component="main" >
          <CssBaseline />
          <Grid
            item
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(/static/' + randomScreen + '.jpg)', // 'url(https://source.unsplash.com/random)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textShadow: "2px 2px 2px black",
              color: 'white'
            }}
          >
            <h1 style={{alignItems: 'center', textAlign: 'center'}}>
              Build a Bridge between Words and the World
            </h1>
            <Container>
              <p style={{alignItems: 'center', fontSize: '22px', textAlign: 'center'}}>
              Build a Bridge between Words and the World. Whether you're an author looking to find a literary agent or an agent looking for that next big deal, we've got you covered. 
              Sign up now and connect with professionals across the industry.
              </p>
            </Container>

          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar> */}
              <Typography component="h1" variant="h5">
                Sign up for free
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="email"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  label="Username"
                  id="username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  autoComplete="username"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  error={ password && (password !== password2) }
                  helperText={ password && (password !== password2) ? "Passwords do not match": ""}
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  value={password2}
                  onChange={e => setPassword2(e.target.value)}
                  autoComplete="current-password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="first_name"
                  label="First Name"
                  id="first-name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  autoComplete="given-name"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="last_name"
                  label="Last Name"
                  id="last-name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  autoComplete="family-name"
                />
                {/* <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Account Type"
                  onChange={e => setType(e.target.value)}
                >
                  <MenuItem value={'AUTHOR'}>Author</MenuItem>
                  <MenuItem value={'AGENT'}>Agent</MenuItem>
                  <MenuItem value={'EDITOR'}>Editor</MenuItem>
                </Select>
                <br/>                

                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <br/> 
                <Alert style={{visibility: error ? 'visible' : 'hidden' }} severity="error">
                  Error Creating Your Account
                </Alert>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => register_user()}
                >
                  Sign up
                </Button>
                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid> */}
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}