import React, { useState, useContext, useEffect } from 'react';
import useFetch from 'use-http'

import { MainContext } from '../../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { bookStatusOptions, genreOptions } from '../../Util/constants';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'

export default function BookeEditModal(props) {
  const { jwt } = useContext(MainContext);
  const [error, setError] = useState('')
  const { patch, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt }, cachePolicy: 'no-cache' })
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('')
  const [genre, setGenre] = useState('')
  const [description, setDescription] = useState('')
  const [year, setYear] = useState(2023)
  const [isbn10, setIsbn10] = useState('')
  const [isbn13, setIsbn13] = useState('')
  const [coverPic, setCoverPic] = useState(null);
  const [imgData, setImgData] = useState(null);

  const onChangeProfilePic = (e) => {
    if (e.target.files[0] === undefined) return;
    setCoverPic(e.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  };


  useEffect(() => {
    setTitle(props.book.title)
    setStatus(props.book.status)
    setGenre(props.book.genre)
    setDescription(props.book.description)
    setYear(props.book.year)
    setIsbn10(props.book.isbn10)
    setIsbn13(props.book.isbn13)
    setImgData(props.book.cover_pic)
  }, [props.book])

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const updatebook = async () => {
    setError('')
    const formData = new FormData();
    formData.append('title', title);
    formData.append('status', status);
    formData.append('genre', genre);
    formData.append('description', description);
    if (year) { formData.append('year', year) }
    if (coverPic) formData.append('cover_pic', coverPic);
    if (isbn10) { formData.append('isbn10', isbn10) }
    if (isbn13) { formData.append('isbn13', isbn13) }
    await patch(`/api/book/${props.book.id}`, formData);
    if (response.ok) {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
              <AutoStoriesIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Edit your book details
            </Typography>
          </Center>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Center>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                onChange={onChangeProfilePic}
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Avatar variant="square" sx={{ mt: 1, height: 250, width: 250 }}>
                  {!imgData && "Cover"}
                  {imgData && <img src={imgData} />}
                </Avatar>
              </label>
            </Center>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              value={genre}
              labelId="genre-helper-label"
              id="genre-select-helper"
              label="Genre"
              select
              onChange={e => setGenre(e.target.value)}>
              {genreOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              value={status}
              label="Status"
              select
              onChange={e => setStatus(e.target.value)}>
              {bookStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              error={year && (year < 1800 || year > 2100)}
              helperText={year && (year < 1800 || year > 2100) ? "Invalid year" : ''}
              id="year"
              label="Year"
              name="year"
              value={year}
              onChange={e => setYear(e.target.value)}
            />
            } */}
            {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              id="isb10"
              label="Isbn10"
              name="isb10"
              value={isbn10}
              onChange={e => setIsbn10(e.target.value)}
            />
            }
            {status === 'PUBLISHED' && <TextField
              margin="normal"
              fullWidth
              id="isbn13"
              label="Isbn13"
              name="isbn13"
              value={isbn13}
              onChange={e => setIsbn13(e.target.value)}
            />
            }
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={5}
              id="description"
              label="Description"
              name="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <Alert style={{ visibility: error ? 'visible' : 'hidden' }} severity="error">
              {error}
            </Alert>

          </Box>
          <Center>
            <Stack direction="row" spacing={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => updatebook()}>
                Save
              </Button>
              <Button
                type="submit"
                fullWidth
                color="error"
                variant="contained"
                onClick={props.onClose}>
                Cancel
              </Button>
            </Stack>
          </Center>

        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}