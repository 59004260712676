import React from 'react';
import Router from './Router';

import {MainContextProvider} from './Providers/MainContext'

export default function App() {
  return (
    <MainContextProvider>
      <Router/>
    </MainContextProvider>
  );
}