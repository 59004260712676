import React, { useState, useContext } from 'react';
import useFetch from 'use-http'

import {MainContext} from '../../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'
import { useNavigate } from 'react-router-dom';

export default function ConnectionModal(props) {
  const { jwt } = useContext(MainContext);
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {headers: {"Authorization": "Bearer " + jwt}, cachePolicy: 'no-cache'})

  const addConnection = async () => {

    setError('')
    await post('/api/connection', { acceptor: props.profile.id });

    if (response.ok){
      props.onClose()
      navigate('/connection', {replace: true})
    } 
  }

  return(
    <Dialog open={props.open} onClose={props.onClose}>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" style={{alignItems: 'center'}}>
        <Center>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
            <PersonAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Add {props.profile.name} as a connection 
          </Typography>
        </Center>
        <br/> 
        {error && <Alert severity="error">{error}</Alert>}
        <Center>
          <Stack direction="row" spacing={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => addConnection()}>
              Add 
            </Button>
            <Button
              type="submit"
              fullWidth
              color="error"
              variant="contained"
              onClick={props.onClose}>
              Cancel
            </Button>
          </Stack>
        </Center>

      </DialogContentText>
    </DialogContent>
  </Dialog>
  )
}