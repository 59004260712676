import React, { useState } from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'

export default function BookViewModal(props) {
  const [error] = useState('')

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
              <AutoStoriesIcon />
            </Avatar>

          </Center>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Center>
              <img style={{ height: 300, width: 'auto' }} src={props.book.cover_pic} />
            </Center>
            <Center>
              <Typography sx={{ mt: 3 }} component="h1" variant="h3">
                {props.book.title}
              </Typography>
            </Center>
            <Typography component="h1" variant="h6">
              {props.book.description}
            </Typography>
            <Typography component="h1" variant="h6">
              Genre: {props.book.genre}
            </Typography>
            <Typography component="h1" variant="h6">
              Status: {props.book.status}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}