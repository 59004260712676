import React, { useState } from 'react';
import useFetch from 'use-http'

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../Components/Center'

export default function ForgotUsernameModal(props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, { cachePolicy: 'no-cache' })

  const sendForgotUsername = async () => {
    setError('')
    await post('/api/forgot-username', { email });
    if (response.ok) {
      setSuccess(true)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendForgotUsername()
    }
  }

  return (
    <Dialog open={props.open} onClose={() => {setSuccess(false); props.onClose()}}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <br />
            <br />
            <Typography component="h1" variant="h5">
              Get Username
            </Typography>
          </Center>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            {!success && <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onKeyDown={handleKeyDown}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
            />
            }
            {success && <Alert severity="info"><Typography>If an account is associated with that email, you will receive a reset link shortly.</Typography></Alert>}

          </Box>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          {!success && <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => sendForgotUsername()}>
            Get Username
          </Button>}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}