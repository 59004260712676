const md = `
# What if my books is rejected by a publisher

_March 12, 2022 by Paul

Rejection is part of life. No one gets everything they want or everything they ask for so rejection
is already part of your life. The list is endless from not getting a promotion or a better job or a
raise and on and on the list goes. Pity the poor salesperson. Most sales people close about half of
their customers. Actors and actresses know all about rejection. They can be rejected 100s of
times before luck, success and good fortune shines its bright little halo on them. But it is never
luck but persistence and want power that makes it happen. Writing vies with acting for the
frequency of rejection. By far and away they are both at top of the list for being rejected. You
will be told to get lost by agents by the bushel full. Even people who read your work product will
not like it and give you terrible reviews.
It is part and parcel of the job.
But it gets a lot easier when you have a support network of others who know your work and love
it. It is so much easier to handle rejection if you have 20-30 people who have already read your
work, constructively commented on it, and know you. Of course if you have done your initial
marketing by becoming connected in the community others have read your work and
constructively commented on it and you responded by producing a better work product. That
rejection made you a better writer.
`
export default md;