import Box from '@mui/material/Box';

export default function Center(props) {

    const { children } = props

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
            }}
            {...props}
        >
            {children}
        </Box>
    );
}
