import React, { useState, useContext } from 'react';
import useFetch from 'use-http'

import { MainContext } from '../../Providers/MainContext';

import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';

import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import MessageIcon from '@mui/icons-material/Message';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import Center from '../../Components/Center'
import { useNavigate } from 'react-router-dom';

export default function MessageModal(props) {
  const { jwt } = useContext(MainContext);
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, { headers: { "Authorization": "Bearer " + jwt } })

  const addChat = async () => {
    setError('')
    let _data = await post('/api/chat', { profileId: props.profile.id });
    if (response.ok) {
      navigate(`/chat/${_data.id}`, { replace: true })
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ alignItems: 'center' }}>
          <Center>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mb: 4 }}>
              <MessageIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Start a chat with {props.profile.name}
            </Typography>
          </Center>
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Center>
            <Stack direction="row" spacing={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => addChat()}>
                Add
              </Button>
              <Button
                type="submit"
                fullWidth
                color="error"
                variant="contained"
                onClick={props.onClose}>
                Cancel
              </Button>
            </Stack>
          </Center>

        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}