import jwt_decode from "jwt-decode";


export const checkJwt = (jwt) => {
  if (!jwt) return false
  const decoded = jwt_decode(jwt);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false
  }
  return true
}