import React from "react"
import useCookie from 'react-use-cookie';

const MainContext = React.createContext();

function MainContextProvider({children}) {
  const [jwt, setJwt] = useCookie('token', '');
  const [profile, setProfile] = useCookie('profile', '');
  const [theme, setTheme] = useCookie('theme', 'dark');

  return (
    <MainContext.Provider value={{jwt, setJwt, profile, setProfile, theme, setTheme}}>
        {children}
    </MainContext.Provider>
  )
}

export {MainContextProvider, MainContext}