import Container from '@mui/material/Container';
import Typography from '@mui/material/Container';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function ConstructionPage() {

  return(
    <Container maxWidth="xl">
      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom>
          <ConstructionIcon/>
          Literary Co is still under construction
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Check back in the next for weeks for the full website.
        </Typography>
      </Container>
    </Container>
  );
}