import React, { useState } from 'react';

import { useSearchParams } from "react-router-dom";
import useFetch from 'use-http'
import { useNavigate } from 'react-router-dom'
import AccountCircle from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { patch, response, error } = useFetch(process.env.REACT_APP_API_URL, { cachePolicy: 'no-cache' })

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const resetPassword = async () => {
    await patch(`/api/reset-password`, { email, password, confirmPassword, token: searchParams.get('token') });
    if (response.ok) {
      alert('Password Reset Successfully')
      navigate('/')
    }
  }

  return (
    <Container maxWidth="xl">
      <CssBaseline />

      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AccountCircle />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              autoComplete="current-password"
            />

            <Alert style={{ visibility: error ? 'visible' : 'hidden' }} severity="error">
              Error Reseting Password, Try requesting a new reset
            </Alert>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => resetPassword()}>
              Update Password
            </Button>

          </Box>
        </Box>
      </Container>
    </Container>
  );
}